/* styles.css */
.scanner-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.iframe-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
